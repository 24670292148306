// ============================================
// DEFAULT CONTENT BLOCKS
// ============================================

.c-default-content-blocks {
    $c: &;

    &__row {
        &:first-child {
            @apply mt-0;
        }
        &:last-child {
            @apply mb-0;
        }
    }
    
}
