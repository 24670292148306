
// ============================================
// PRIMARY NAV
// ============================================

.c-primary-nav {
    $c: &;

    &__item {
        &:first-child {
            #{$c} {
                // X
                &__link {
                    
                    @include breakpoint (lg) {
                        @apply pl-0;    
                    }
                }
            }
        }
    }

    &__label {
        font-size: 19px;
        @apply inline-block text-default leading-none whitespace-no-wrap;
    }

    &__link {
        @apply py-1.5 px-2 text-center;
        @include breakpoint (lg) {
            @apply text-left;
        }
        &:hover,
        &:active {
            #{$c} {
                // X
                &__label {
                    @apply text-primary;
                }
            }
        }

        &.is-active {
            #{$c} {
                // X
                &__label {
                    @apply font-primary font-bold text-primary;
                }
            }
            &:hover,
            &:active {
                #{$c} {
                    // X
                    &__label {
                        @apply text-primary;
                    }
                }
            }
        }

        &.boxed {
            padding-top: 10px;
            @apply border border-primary pb-1;
            #{$c} {
                // X
                &__label {
                    @apply uppercase text-13 tracking-wider font-semibold;
                }
            }
            &.is-active,
            &:hover,
            &:active {
                @apply bg-primary;
                #{$c} {
                    // X
                    &__label {
                        @apply text-white;
                    }
                }
            }
        }
    }

    &--header {
        width: 100%;

        transform: translateY( 12px );
        #{$c} {
            // X

            &__list {
                @apply flex-row justify-between;
            }

            &__item {
                position: relative;
                &:hover,
                &:active {
                    #{$c} {
                        // X
                        &__label {
                            @apply text-primary;
                        }
                        &__sub-nav {
                            z-index: 10;

                            overflow: visible;

                            max-height: 500px;

                            transform: translateY($baseline * 2);

                            opacity: 1;
                        }
                    }
                }
                &.is-active{
                    &:hover, &:active{
                        #{$c}{
                            // X
                            &__label{
                                @apply text-default;
                            }
                        }   
                    }
                }
                &.has-children {
                    &:hover,
                    &:active {
                        #{$c} {
                            // X
                            &__label {
                                @apply text-primary;
                                &:before {
                                    transform: translateY(0);
                                    @apply opacity-100;
                                }
                            }
                        }
                    }
                    #{$c} {
                        // X
                        &__label {
                            &:before {
                                position: absolute;
                                bottom: 0;
                                left: 50%;

                                display: block;

                                width: 0;
                                height: 0;
                                margin-left: - $baseline;

                                content: '';
                                transition: transform .3s ease, opacity .3s ease;
                                transform: translateY(100%);

                                opacity: 0;
                                border-width: 0 6px 6px 6px;
                                border-style: solid;
                                border-color: transparent transparent $primary transparent;
                            }
                        }
                    }
                }
                &.boxed {
                    @apply flex flex-col justify-center;
                    &:hover,
                    &:active {
                        #{$c} {
                            // X
                            &__label {
                                @apply text-white;
                            }
                        }
                    }
                }
            }

            &__sub-nav {
                position: absolute;
                z-index: -1;
                top: 27px;
                right: - $baseline * 3;
                left: - $baseline * 3;

                display: block;
                overflow: hidden;

                max-height: 0;

                transition: transform .3s ease, opacity .3s ease, max-height .3s ease;

                opacity: 0;
            }
        }
    }

    &--mobile-nav {
        
        #{$c} {
            // X
            &__list {
                @apply flex-col items-center;
            }

            &__item {
                &.boxed{
                    @apply mt-2;
                }
            }
            
            &__label {
                @apply text-default;
            }
            
            &__link{
                @apply py-1;
            }
        }
    }
}
