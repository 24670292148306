
// ============================================
// INPUT
// ============================================

.c-input {
    @apply w-full py-2 px-3 border border-grey-lighter bg-white font-primary tracking-wide;
    @include placeholder($grey, '', '', '', '', 1);
    &.has-error{
        @apply border-b border-solid border-red text-red;
        @include placeholder($red, '', '', '', '', 1);
    }
}
