// ============================================
// PEDIGREE
// ============================================

.c-pedigree {

    $c: &;

    &__parents{
        flex: 1 1 auto;
        &--has-multiple{
            &:before{
                content: "";
                @apply w-px bg-white-20 absolute -ml-3;
                top: 25%;
                bottom: 25%;
            }
            &:after{
                content: "";
                @apply h-px w-3 bg-white-20 absolute -ml-6;
                top: 50%;
            }
        }
    }
    
    &__row{
        &--has-parent{
            &:before{
                content: "";
                @apply h-px w-3 bg-white-20 absolute pin-l -ml-3;
                top: 50%;
            }
        }
    }
    &__column{
        flex: 1 1 auto;
    }
    &__row{
        flex: 1 1 auto;
    }
    &__content{
        flex: 1 1 auto;
    }

}