
// ============================================
// CHECKBOX
// ============================================

.c-checkbox {
    $c: &;
    &__label {
        font-family: inherit;
        font-size: inherit;
        @apply pl-3.5 relative block;
        &:before,
        &:after {
            
            @apply absolute pin-t pin-l inline-block transition rounded-full;
            content: '';
        }
        &:before {
            @apply w-2.5 h-2.5 bg-white border border-grey-lighter;
        }
        &:after {
            @apply w-1.5 h-1.5 ml-0.5 mt-0.5 opacity-0 bg-primary;
        }
        a {
            @apply inline-block transition;
            @apply text-default font-semibold;
            &:hover,
            &:active {
                @apply text-default;
            }
        }
    }
    
    &__input {
        @apply absolute;
        left: - 2000px;
        &:focus{
            & + .c-checkbox__label {
                &:before{
                    @apply border-black;
                }
            }
        }
        &:checked {
            & + .c-checkbox__label {
                &:after {
                    @apply opacity-100;
                }
            }
            &:focus{
                & + .c-checkbox__label {
                    &:before{
                        @apply border-grey-lighter;
                    }
                }
            }
        }
    }
    
    &.has-error{
        #{$c}{
            // X
            &__label{
                &:before{
                    @apply border-red;   
                }
            }
        }
    }
    
}
