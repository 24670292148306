
// ============================================
// SLICK DOTS
// ============================================

.c-slick-dots {
    
    .slick-dots{
        @apply flex justify-center items-center;
        @include breakpoint (lg) {
            @apply justify-end;
        }
    }
    
    li {
        @apply leading-0 flex items-center justify-center w-1 h-4;
        
        button {
            @apply relative inline-block align-top p-0 cursor-pointer rounded-none bg-white-20 text-0 rounded-full;
            width: 6px;
            height: 6px;
            
            text-indent: - 9999px;

            &:active,
            &:hover {
                @apply bg-white;
            }
        }

        &.slick-active {
            button {
                @apply bg-primary;
                &:hover,
                &:active {
                    @apply bg-primary;
                }
            }
        }
    }
    
    &--gallery{
        li{
            button{
                @apply bg-grey-dark;
                &:hover, &:active{
                    @apply bg-grey-light;
                }
            }
        }
    }
}
