
// ============================================
// SVG URL
// ============================================

$list-style-arrow: '<svg width="5" height="8" viewBox="0 0 5 8"><path fill-rule="evenodd" d="M1.22951135,52.5706912 L4.43074259,55.8389811 C4.4769142,55.8861086 4.5,55.9395197 4.5,56.0007855 C4.5,56.0612657 4.4769142,56.1146768 4.43074259,56.1618043 L1.22951135,59.4300943 C1.18333975,59.4764363 1.1302424,59.5 1.07098884,59.5 C1.01173528,59.5 0.958637938,59.4764363 0.912466333,59.4300943 L0.569257407,59.0789946 C0.523085802,59.0318671 0.5,58.978456 0.5,58.9171903 C0.5,58.8567101 0.523085802,58.8032989 0.569257407,58.7561715 L3.26952674,56 L0.569257407,53.2438285 C0.523085802,53.1967011 0.5,53.1432899 0.5,53.0820242 C0.5,53.021544 0.523085802,52.9681329 0.569257407,52.9210054 L0.912466333,52.5706912 C0.958637938,52.5235637 1.01173528,52.5 1.07098884,52.5 C1.1302424,52.5007855 1.18333975,52.5243492 1.22951135,52.5706912 Z" transform="translate(0 -52)"/></svg>';

$list-style-dot: '<svg width="4" height="4" viewBox="0 0 4 4"><circle cx="613" cy="857" r="2" transform="translate(-611 -855)"/></svg>';

$checkmark: '<svg height="11" viewBox="0 0 14 11" width="14"><path fill-rule="evenodd" d="m16.979 9 2.021 1.991-8.413 8.554-5.587-5.276 1.946-2.064 3.568 3.369z" transform="translate(-5 -9)"/></svg>';

$dot: '<svg height="8" viewBox="0 0 8 8" width="8"><circle cx="4" cy="4" fill-rule="evenodd" r="4"/></svg>';
//
//  Function to create an optimized svg url
//
@function svg-url($svg, $color: "") {
    //    
    //  Chunk up string in order to avoid 
    //  "stack level too deep" error
    //     
    $encoded: '';
    $slice: 2000;
    $index: 0;
    $loops: ceil(str-length($svg)/$slice);
    //
    //  Add missing namespace
    //
    @if not str-index($svg,xmlns) {
        $svg: str-replace($svg, '<svg','<svg fill="'+ $color +'" xmlns="http://www.w3.org/2000/svg"');
    }
    @for $i from 1 through $loops {
        $chunk: str-slice($svg, $index, $index + $slice - 1);
        //
        //   Encode 
        //
        $chunk: str-replace($chunk,'"', '\'');
        $chunk: str-replace($chunk,'%', '%25');
        $chunk: str-replace($chunk,'&', '%26');
        $chunk: str-replace($chunk,'#', '%23');
        $chunk: str-replace($chunk,'{', '%7B');
        $chunk: str-replace($chunk,'}', '%7D');
        $chunk: str-replace($chunk,'<', '%3C');
        $chunk: str-replace($chunk,'>', '%3E');
        // 
        //    The maybe list 
        //
        //    Keep size and compile time down
        //    ... only add on documented fail 
        // 
        //  $chunk: str-replace($chunk, '|', '%7C');
        //  $chunk: str-replace($chunk, '[', '%5B');
        //  $chunk: str-replace($chunk, ']', '%5D');
        //  $chunk: str-replace($chunk, '^', '%5E');
        //  $chunk: str-replace($chunk, '`', '%60');
        //  $chunk: str-replace($chunk, ';', '%3B');
        //  $chunk: str-replace($chunk, '?', '%3F');
        //  $chunk: str-replace($chunk, ':', '%3A');
        //  $chunk: str-replace($chunk, '@', '%40');
        //  $chunk: str-replace($chunk, '=', '%3D');      
        $encoded: #{$encoded}#{$chunk};
        $index: $index + $slice;
    }
    @return url('data:image/svg+xml,#{$encoded}');
}

//  Background svg mixin          
@mixin background-svg($svg, $default-color: "") {
    background-image: svg-url($svg, $default-color);
}

//  Helper function to replace characters in a string
@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);
    @return if($index,
    str-slice($string, 1, $index - 1) + $replace +
    str-replace(str-slice($string, $index +
    str-length($search)), $search, $replace),
    $string);
}