
// ============================================
// MOBILE NAV TRIGGER
// ============================================

.c-mobile-nav-trigger {
    $c: &;
    &__button{
        @apply border border-primary border-solid;
        @media screen and (max-width: $md){
            @apply py-2;
        }
        
    }
}
