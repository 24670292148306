
// ============================================
// LIST STYLING
// ============================================

ol {
    li {
        list-style-position: inside;
    }
}
ul {
    @apply m-0 p-0;
    li {
        list-style-type: none;
    }
}
