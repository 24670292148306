
// ============================================
// LANGUAGE NAV
// ============================================

.c-language-nav {
    $c: &;

    &__item {
        
        &.is-active {
            #{$c} {
                // X
                &__label {
                    @apply text-default border-b border-secondary;
                }
                &__link {
                    &:hover,
                    &:active {
                        #{$c} {
                            // X
                            &__label {
                                @apply text-default;
                            }
                        }
                    }
                }
            }
        }
    }
    
    &__label{
        
    }
    
    &__link {
        &:hover,
        &:active {
            #{$c} {
                // X    
                &__label {
                   
                }
            }
        }
    }
    
    


    // --HEADER
    // ============================================

    &--header {
    }


    // -- MOBILE
    // ============================================

    &--mobile {
    }
    
    
}
