// ============================================
// PRIMARY SUB NAV
// ============================================

.c-primary-sub-nav {

    $c: &;
    @apply mb-1;
    @include breakpoint (lg) {
        @apply bg-primary py-1 mb-0;
    }
    
    &__label{
        @apply inline-block text-14 text-white-50 py-0.5;
        @include breakpoint (lg) {
            @apply text-white uppercase tracking-wider font-semibold;
        }
    }
    
    &__item{
        &:first-child{
            
            #{$c}{
                // X
                &__label{
                    @apply pt-0;
                    @include breakpoint (lg) {
                        @apply pt-0.5;
                    }    
                }
            }
        }
    }
    &__link{
        @apply block px-3 text-center;
        @include breakpoint (lg) {
            @apply bg-primary;
        }
        &.is-active{
            
            #{$c}{
                // X
                &__label{
                    @apply font-bold text-primary;
                    @include breakpoint (lg) {
                        @apply text-white;
                    }
                }
            }
            
            &:hover, &:active{
                #{$c}{
                    // X
                    &__label{
                        @apply text-primary;
                        @include breakpoint (lg) {
                            @apply text-white;    
                        }
                    }
                }
            }
        }
        
        &:hover, &:active{
            #{$c}{
                // X
                &__label{
                    @apply text-primary;
                    @include breakpoint (lg) {
                        @apply text-white-40;    
                    }
                }
            }
        }
    }
    
}