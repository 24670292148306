
// ============================================
// TABLE
// ============================================

table {
    @apply w-full border-collapse;
    
    th,
    tr td {
        @apply p-2;
    }
    tr{
        
    }
    td{
        @apply align-top;
    }
    thead {
        tr {
            @apply bg-default;
            th,
            td {
                @apply text-left text-white;
            }
        }
    }
    tbody {
        td {
            @apply border border-b-0;
        }
        tr{
            &:last-child{
                td{
                    @apply border border-b;
                }
            }
        }
    }
    tfoot {
        tr,
        td {
            @apply p-0;
        }
    }
}
