
// ============================================
// FIGURE
// ============================================

.c-figure {
    $c: &;

    @apply relative overflow-hidden text-0 w-full h-auto;
    
    &__inner {
        @apply absolute z-0 pin-x pin-y bg-bordeaux;
        &--transparent{
            @apply bg-transparent;
        }
    }
   
    &__image {
        @apply relative z-1 block w-full h-auto transition;
    }

    &__loader-holder {
        @apply absolute z--1 pin-x pin-y flex flex-col items-center justify-center;
    }
    
    &__overlay{
        @apply absolute z-2 pin-y pin-x bg-secondary-20 opacity-0 transition;
    }
    
    &__play-button-holder{
        @apply absolute pin-y pin-x z-3 flex justify-center items-center;
    }
    
    &__svg{
        &--play{
            @apply w-8 h-8 text-white;
        }
    }
    
    &--zoom{
        &:hover, &:active{
            #{$c}{
                // X
                &__image{
                    transform: scale(1.05);    
                }
            }
        }
    }
    
    &--video{
        #{$c}{
            // X
            &__overlay{
                @apply opacity-100 bg-secondary-60;
            }
        }
    }
    
}
