
// ============================================
// SPINNER
// ============================================

.c-spinner {
    $c: &;
    &__icon {
        animation: clockwise .3s linear infinite;
    }
    &__cut {
        width: $baseline * 1.5 / 2;
    }
    &__donut {
 
        animation: donut-rotate 1000ms cubic-bezier(.4,0,.22,1) infinite;

        border: $baseline / 4 solid #000000;
        border-bottom-color: transparent;
        border-left-color: transparent;
        border-radius: 50%;
        background: none;
    }

    &--figure {
        #{$c} {
            // X
            &__donut {
                @apply border-grey-light;
            }
        }
    }

    &--footer {
        #{$c} {
            // X
            &__donut {
                @apply border-grey-light;
            }
        }
    }
    
    &--button {
        #{$c} {
            // X
            &__donut {
                @apply border-grey-light;
            }
        }
    }
}

@keyframes clockwise {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes donut-rotate {
    0% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(-140deg);
    }
    100% {
        transform: rotate(0);
    }
}
