
// ============================================
// BUORG
// ============================================

#buorg {
    
    @apply bg-primary border-0 text-white shadow-none font-primary;

    div a {
        @apply py-1 px-2 rounded-none;
    }
}

#buorgig {
    @apply py-1 px-2 rounded-none;
}

body{
    .buorg{
        @apply border-t-0;
    }
    .buorg-buttons{
        @apply inline-block;
    }
}