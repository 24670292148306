
// ============================================
// BREADCRUMBS
// ============================================

.c-breadcrumbs {
    $c: &;
    
    &__item{
        @apply text-0 flex items-center mt-1;
    }
    
    &__label{
        @apply text-grey text-12;
        &--current{
            @apply font-semibold;
        }
    }
    
    &__svg{
        width: 4px;
        height: 7px;
        @apply align-top mx-1 text-grey;
    }
    
    &__link{
        @apply text-0 flex items-center;
        &:hover, &:active{
            #{$c}{
                // X
                &__label{
                    @apply text-default;       
                }
            }
        }
    }
}
