
// LEGAL-NAV
// ============================================

.c-legal-nav {
    $c: &;

    &__list {
        @apply -ml-6;
    }

    &__item {
        @apply w-full pl-6;

        @include breakpoint (sm) {
            @apply w-auto;
        }
    }

    &__label {
        @apply text-12 text-grey tracking-wider uppercase;
    }

    &__link {
      
        &:hover,
        &:active {
            #{$c} {
                // X
                &__label {
                   @apply text-default;
                }
            }
        }
    }
}
