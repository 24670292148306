
// ============================================
// FOOTER-NAV
// ============================================

.c-footer-nav {
    $c: &;
    &__list{
        @apply flex-col;
    }
    
    &__label{
        @apply text-14 transition font-semibold tracking-wider text-default uppercase leading-loose;
        &--heading{
            @apply whitespace-no-wrap;
        }
        &--child{
            @apply text-default font-light normal-case tracking-normal leading-normal;
        }
        
    }
    
    &__item{
        &:last-child{
            @apply mb-0;
        }
    }
    &__link{
        &:hover, &:active{
            #{$c}{
                // X
                &__label{
                    @apply text-primary;
                }
            }
        }
    }
}
