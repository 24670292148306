
// ============================================
// GENERICS FONTS
// ============================================

@font-face {
    font-family: 'Acta-Medium';
    src: url('../fonts/ActaDisplay-Medium.woff2') format('woff2'),
        url('../fonts/ActaDisplay-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Acta-Bold';
    src: url('../fonts/ActaDisplay-Bold.woff2') format('woff2'),
        url('../fonts/ActaDisplay-Bold.woff') format('woff');
}