
// ============================================
// SOCIAL NAV
// ============================================

.c-social-nav {
    $c: &;
    
    &__label{
        @apply hidden;
    }
    
    &__svg {
        
        @apply text-default;
        &--facebook {
            width: 9px;
            height: 16px;
        }
        &--linkedin {
            width: 14px;
            height: 14px;
        }
        &--twitter {
            width: 15px;
            height: 15px;
        }
        &--instagram {
            width: 16px;
            height: 16px;
        }
        &--youtube {
            width: 14px;
            height: 10.02px;
        }
    }
    
    &__link {
               
        &:hover,
        &:active {
            #{$c}{
                // X
                &__svg{
                    @apply text-primary;   
                }
            }
        }
    }


    // HEADER
    // ============================================
    &--header {
        #{$c} {
            // X
            &__svg {
                
                &--facebook {
                    
                }
                &--linkedin {
                    
                }
                &--twitter {
                    
                }
                &--instagram {
                    
                }
                &--youtube {
                    
                }
            }
            
        }
    }


    // FOOTER
    // ============================================
    &--footer {
        #{$c} {
            // X
            &__svg {
                &--facebook {
                    
                }
                &--linkedin {
                    
                }
                &--twitter {
                    
                }
                &--instagram {
                    
                }
                &--youtube {
                    
                }
            }
            &__link {
               
                &:hover,
                &:active {
                    
                }
            }
        }
    }

    &--mobile-nav{
        #{$c}{
            // X
            &__list{
                @apply justify-center;
            }
        }
    }

    // BUTTONS
    // ============================================
    &--dots {
        #{$c} {
            // X
            &__svg{
                @apply text-primary;
                &--facebook {
                    width: 8px;
                    height: 15px;
                }
            }
            &__link {
                @apply flex items-center justify-center w-4 h-4 rounded-full border border-grey-lighter text-primary;
                &:hover,
                &:active {
                    @apply bg-primary border-primary;
                    #{$c}{
                        // X
                        &__svg{
                            @apply text-white;
                        }
                    }
                }
            }
        }
    }
    
    &--instagram-feed{
        #{$c}{
            // X
            &__list{
                @apply justify-center;
            }
            &__item{
                @apply hidden;
                &--instagram{
                    @apply block;
                }
            }
            &__link{
                @apply text-center;
                &:hover, &:active{
                    #{$c}{
                        // X
                        &__label{
                            @apply text-primary;
                        }
                    }
                }
            }
            &__icon{
                @apply inline-flex;
            }
            &__svg{
                @apply text-primary;
            }
            &__label{
                @apply pt-1 block text-14 font-bold uppercase tracking-wide leading-normal text-default;
            }
        }
    }
}
