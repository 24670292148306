// ============================================
// HOME INTRO SLIDER
// ============================================

.c-home-intro-slider {

    $c: &;
    &__slides{
        @apply absolute;
        @include breakpoint (lg) {
            @apply relative;
        }
        .slick-list{
            height: 100%;
            @include breakpoint (lg) {
                height: auto;
            }
        }
        .slick-slide{
            
            @include breakpoint ($lg - 1 down) {
                height: 100% !important;
            }
        }
    }
    &__bg{
        background-color: #0F0E18;       
    }
}