
// ============================================
// VENDOR: PHOTOSWIPE
// ============================================

@import '../../../node_modules/photoswipe/src/css/main';
@import '../../../node_modules/photoswipe/src/css/default-skin/default-skin';

.pswp__caption{
    background: transparent !important;
}
.pswp__caption__center{
    @apply text-center text-12;
}