
@mixin placeholder($color, $font:"", $font-style:"", $text-transform: "", $textshadow:"", $opacity:"") {
    &::-webkit-input-placeholder {
        color: $color;
        @if $font != "" {
            font-family: $font;
        }
        @if $font-style != "" {
            font-style: $font-style;
        }
        @if $text-transform != "" {
            text-transform: $text-transform;
        }
        @if $textshadow != "" {
            text-shadow: 1px 1px 0 $textshadow;
        }
        @if $opacity != "" {
            opacity: $opacity;
        }
    }
    &:-moz-placeholder {
        color: $color;
        @if $font != "" {
            font-family: $font;
        }
        @if $font-style != "" {
            font-style: $font-style;
        }
        @if $text-transform != "" {
            text-transform: $text-transform;
        }
        @if $textshadow != "" {
            text-shadow: 1px 1px 0 $textshadow;
        }
        @if $opacity != "" {
            opacity: $opacity;
        }
    }
    &::-moz-placeholder {
        color: $color;
        @if $font != "" {
            font-family: $font;
        }
        @if $font-style != "" {
            font-style: $font-style;
        }
        @if $text-transform != "" {
            text-transform: $text-transform;
        }
        @if $textshadow != "" {
            text-shadow: 1px 1px 0 $textshadow;
        }
        @if $opacity != "" {
            opacity: $opacity;
        }
    }
    &:-ms-input-placeholder {
        color: $color;
        @if $font != "" {
            font-family: $font;
        }
        @if $font-style != "" {
            font-style: $font-style;
        }
        @if $text-transform != "" {
            text-transform: $text-transform;
        }
        @if $textshadow != "" {
            text-shadow: 1px 1px 0 $textshadow;
        }
        @if $opacity != "" {
            opacity: $opacity;
        }
    }
}