
// ============================================
// BASE DECLARATIONS
// ============================================

$baseline:          8px;

$xxl:               1920px;
$xl:                1440px;
$lg:                1200px;
$ml:                1024px;
$md:                960px;
$sm:                768px;
$xs:                375px;

$videoAspectRatio: 56.25%;
