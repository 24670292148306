// ============================================
// INNER PAGE NAV
// ============================================

.c-inner-page-nav {
    $c: &;
    
    &__item{
        @apply flex items-center w-1/2 pl-4 pt-4;
    }
    &__icon{
        @apply w-4;
    }
    &__svg {
        @apply text-primary;
        &--pedigree {
            width: 27px;
            height: 25px;
        }
        &--damline {
            width: 24px;
            height: 24px;
        }
        &--videos {
            width: 24px;
            height: 24px;
        }
        &--related-news {
            width: 24px;
            height: 20px;
        }
    }

    &__label {
        @apply text-default text-18 ml-2 relative;

        &:before {
            content: '';
            transition: background .6s ease, -webkit-transform .7s cubic-bezier(.19,1,.22,1);
            transition: transform .7s cubic-bezier(.19,1,.22,1), background .6s ease;
            transform: scaleX(1);
            transform-origin: left;
            @apply absolute -mb-0.5 pin-l pin-b w-full h-px bg-primary;
        }
    }

    &__link {
        @apply inline-flex items-center text-0 leading-none;
        &:hover, &:active{
            #{$c}{
                // X
                &__label{
                    &:before{
                        transform: scaleX(0);
                        transform-origin: right;
                    }
                    
                }
            }
           
        }
    }
}
