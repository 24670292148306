
// ============================================
// PWSP VENDOR SETTINGS
// ============================================

$pswp__show-hide-transition-duration: 333ms;
$pswp__controls-transition-duration: 333ms;
$pswp__background-color: #000;
$pswp__placeholder-color: #222;
$pswp__box-sizing-border-box: true;
$pswp__root-z-index: 1500;
$pswp__assets-path: '../images/vendor/photoswipe/';
$pswp__error-text-color: #CCC;
$pswp__include-minimal-style: true;
