
// ============================================
// * / HTML / BODY
// ============================================

*,
*:after,
*:before {
    @apply m-0 p-0;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    text-rendering: optimizeLegibility;
}

::-moz-selection{
    @apply bg-white text-black;
}
::selection {
    @apply bg-white text-black;
}

html,
body {
    @apply w-full h-full;
}

html {
    @apply relative min-h-full transition;
    
    // &.is-loading {
    //     opacity: 0;
    // }
    // &.is-loaded {
    //     opacity: 1;
    // }
    
}

body {
    @apply relative h-full m-0 bg-secondary text-default font-primary leading-normal;
}
