
// ============================================
// CONTAINER
// ============================================

.c-container {
    
    @apply relative w-full mx-auto px-3;
    @include breakpoint (xs) {
        @apply px-4;
    }
    @include breakpoint (sm) {
        @apply px-6;
    }
    
    @include breakpoint (xl) {
        max-width: $xl + $baseline * 12;
    }
    
    &--static{
        @apply static;
    }
    
    &--no-padding-on-sm {
        @include breakpoint ($sm - 1 down) {
            @apply p-0;
        }
    }
    
    &--no-padding-on-md {
        @include breakpoint ($md - 1 down) {
            @apply p-0;
        }
    }
    
}
