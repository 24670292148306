
// ============================================
// SITE WRAPPER
// ============================================

.c-site-wrapper {
    $c: &;
    
    @include breakpoint (md) {
        @apply p-2.5;    
    }
}
