
// ============================================
// COOKIE MESSAGE
// ============================================

.c-cookie-message {
    $c: &;

    transform: translateY(100%);
    @apply fixed z-10 pin-b pin-x;
    &__text {
        a {
            @apply whitespace-no-wrap text-white border-b border-white transition inline-block leading-normal;

            &:hover,
            &:active {
                @apply text-white border-transparent;
            }
        }
    }
}
