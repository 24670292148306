
// ============================================
// VENDOR: MAGNIFIC-POPUP
// ============================================

@import '../../../node_modules/magnific-popup/src/css/main';

.mfp-bg {
   @apply bg-black-80;
}

/* overlay at start */
.mfp-fade.mfp-bg {
    @apply transition;

    opacity: 0;
}

/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
    opacity: 1;
}
/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
    opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
    @apply transition;

    opacity: 0;
}
/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
    opacity: 1;
}
/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0;
}

.mfp-content {
    display: inline-flex;
    // height: 100%;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;

    @include IE() {
        overflow: visible;
    }

    -ms-overflow-style: -ms-autohiding-scrollbar;
    -ms-overflow-style: none;
}

.mfp-container {
    position: fixed;

    overflow: hidden;

    padding: $baseline;

    @include IE() {
        overflow: visible;

        -ms-overflow-style: -ms-autohiding-scrollbar;
        -ms-overflow-style: none;
    }
}

.mfp-zoom-out {
    .mfp-with-anim {
        transition: all .3s ease-in-out;
        transform: scale(1.3);

        opacity: 0;
    }

    &.mfp-bg {
        transition: all .3s ease-out;

        opacity: 0;
    }

    /* animate in */
    &.mfp-ready {
        .mfp-with-anim {
            transform: scale(1);

            opacity: 1;
        }
        &.mfp-bg {
            opacity: 1;
        }
    }

    /* animate out */
    &.mfp-removing {
        .mfp-with-anim {
            transform: scale(1.2);

            opacity: 0;
        }
        &.mfp-bg {
            opacity: 0;
        }
    }
}
