
// ============================================
// ANIMATIONS
// ============================================

@keyframes clockwise {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes arrow-bounce {
    0%   { 
        
    }
    50% {
        transform: translateY($baseline * 2);
    }
    100%{
        transform: translateY(0);
    } 
}