
// ============================================
// AOS VENDOR SETTINGS
// ============================================

$hamburger-padding-x:                        $baseline * 2;
$hamburger-padding-y:                        $baseline * 1.5;
$hamburger-layer-width:                      $baseline * 2;
$hamburger-layer-height:                     1px;
$hamburger-layer-spacing:                    3px;
$hamburger-layer-color:                      $primary;
$hamburger-layer-border-radius:              0px;
$hamburger-has-border:                       false;
$hamburger-border-width:                     $hamburger-layer-height;
$hamburger-border-color:                     $primary;
$hamburger-hover-opacity:                    1;
$hamburger-hover-transition-duration:        .3s;
$hamburger-hover-transition-timing-function: ease;
$hamburger-label-color:                      $default;

// Types (Remove or comment out what you don’t need)
$hamburger-types: (
//   3dx,
//   3dx-r,
//   3dy,
//   3dy-r,
//   3dxy,
//   3dxy-r,
//   arrow,
//   arrow-r,
//   arrowalt,
//   arrowalt-r,
//   arrowturn,
//   arrowturn-r,
//   boring,
//   collapse,
//   collapse-r,
//   elastic,
//   elastic-r,
//   emphatic,
//   emphatic-r,
//   minus,
//   slider,
//   slider-r,
//   spring,
//   spring-r,
//   stand,
//   stand-r,
  spin,
//   spin-r,
//   squeeze,
//   vortex,
//   vortex-r
);