// ============================================
// VIDEO ITEM
// ============================================

.c-video-item {

    $c: &;

    &__link{
        &:hover, &:active{
            #{$c}{
                // X
                &__figure{
                    &-overlay{
                        @apply opacity-100;    
                    }
                    &-image{
                        transform: scale(1.05);    
                    }
                }
                &__link{
                    &-svg{
                        transform: rotate(180deg);
                    }
                    &-label{
                        &:before{
                            transform: scaleX(1);
                            transform-origin: left;
                        }
                    }
                }
                
            }
        }
    }
}