
// ============================================
// HEADER
// ============================================

.c-header {
    $c: &;
    
    &__column{
        &--left,
        &--right{
            @apply w-full;
            
            @include breakpoint (lg) {
                height: 110px;
                width: calc(50% - 95.5px - 48px);
            }
            @include breakpoint (xl) {
                width: calc(40% - 95.5px);
            }
        }
        &--logo{
            
            @include breakpoint (lg) {
                @apply absolute;
                left: 50%;
                margin-left: - 95.5px;
            }
            
        }
    }
}
