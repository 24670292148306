
// ============================================
// FILTER CHECKBOX
// ============================================

.c-filter-checkbox {
    $c: &;
    
    &__label{
        
        &-text{
            
            &:before {
                @apply absolute pin-l pin-b w-full h-px border-t;
                border-color: inherit;
                content: '';
                transition: background .6s ease, -webkit-transform .7s cubic-bezier(.19,1,.22,1);
                transition: transform .7s cubic-bezier(.19,1,.22,1), background .6s ease;
                transform: scaleX(0);
                transform-origin: right;
            }
        }
        
        &-svg{
            width: 14px;
            height: auto;
            @apply hidden;
        }
           
        &:hover, &:active{
            #{$c}{
                // X
                &__label{
                    &-text{
                        @apply text-primary;
                    }
                }
            }
        }
        
    }
    

    &__input {
        
        &:checked {
            & + .c-filter-checkbox__label {
                #{$c}{
                    // X
                    &__label{
                        
                        &-text{
                            @apply text-primary;
                            &:before{
                                @apply border-primary;
                                transform: scaleX(1);
                                transform-origin: left;
                            }
                        }
                        &-svg{
                            @apply text-primary block;
                        }
                    }
                }
               
            
            }
        }
    }
    
    
}
