
// ============================================
// LAZYLOAD
// ============================================

.c-lazyload{
    
    opacity: 0;    
    &.lazyloaded{
        opacity: 1;
    }
    
}