
// ============================================
// STAGGERED CONTENT BLOCKS
// ============================================

.c-staggered-content-block {

    $c: &;
    
    &__year{
        
        @include breakpoint (md) {
            transform: translateY(50%);      
        }  
    }
    
    &--break-out{
        
        #{$c}{
            // X
            &__column{
                @apply w-full;
                @include breakpoint (md) {
                    @apply w-1/2;   
                }
            }
            
            &__bg-wrapper{
                @include breakpoint (md) {
                    width: calc(50vw - #{$baseline * 2.5});
                }
            }
            &__content{
                @apply px-4;
                @include breakpoint (sm) {
                    @apply px-6;
                }
                @include breakpoint (lg) {
                    @apply p-12;
                }
            }
        }
        
        
        &.is-odd{
            #{$c}{
                // X
                &__column{
                    &--image{
                        @include breakpoint (md) {
                            @apply order-1;
                        }
                    }
                    &--content{
                        @include breakpoint (md) {
                            @apply ml-auto;
                            @apply order-2;
                        }
                    }
                }
                &__bg-wrapper{
                    @include breakpoint (md) {
                        @apply pin-l;
                    }
                }
                &__content{
                    @include breakpoint (md) {
                        @apply pl-12 pr-0;
                    }
                }
            }
        }
        
        &.is-even{
            #{$c}{
                // X
                &__column{
                    &--image{
                        @include breakpoint (md) {
                            @apply order-2;
                        }
                    }
                    &--content{
                        @include breakpoint (md) {
                            @apply mr-auto order-1;
                        }
                    }
                }
                &__bg-wrapper{
                    @include breakpoint (md) {
                        @apply pin-r;
                    }
                }
                &__content{
                    @include breakpoint (md) {
                        @apply pr-12 pl-0;
                    }
                }
            }
        }
        
        
        
    }
    
}