
// ============================================
// NEWSLETTER SUBSCRIPTION
// ============================================

.c-newsletter-subscription {
    $c: &;
    @apply w-full;
    
    @include breakpoint (sm) {
        @apply min-w-40 max-w-60;
    }
    
    &__input{
        &.has-error{
            @apply border-red;
        }
        @include breakpoint (sm) {
            @apply py-0;    
        }
    }
    
    &__button{
        @apply w-full;
    }
    
    &__column {
        
        &--email {
            @include breakpoint (sm) {
                @apply flex-1;
            }
        }

        &--submit {
           
        }
        
    }
    
    &--default{
        #{$c}{
            // X
            &__input{
                @apply h-8 border-primary;
                &.has-error{
                    @apply border-red;
                }
                @include breakpoint (sm) {
                    @apply border-r-0;
                }
            }
            &__button{
                @apply h-8 px-6;
            }
        }
    }
    
    &--footer{
        
        #{$c}{
            // X
            &__input{
                @apply border-transparent h-6;
                &.has-error{
                    @apply border-red;
                }
                @include breakpoint (sm) {
                    @apply border-r-0;
                }
            }
            &__button{
                @apply h-6;
                &-label{
                    @apply text-12;
                }
            }
        }
    }
}
