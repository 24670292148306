
// 00-TAILWIND
// ============================================
/* purgecss start ignore */
@tailwind preflight;
/* purgecss end ignore */
@tailwind components;
@tailwind utilities;

/* purgecss start ignore */

// 01-SETTINGS
// ============================================
@import '01-settings/01-settings';

// 02-TOOLS
// ============================================
@import '02-tools/02-tools';

// 03-GENERICS
// ============================================
@import '03-generics/03-generics';


// 04-VENDORS
// ============================================
@import '04-vendors/04-vendors';

// 05-ELEMENTS
// ============================================
@import '05-elements/05-elements';

// 06-OBJECTS
// ============================================
@import '06-objects/06-objects';

// 07-COMPONENTS
// ============================================

@import '07-components/07-components';

/* purgecss end ignore */

// 08-UTILITIES
// ============================================
@import '08-utilities/08-utilities';