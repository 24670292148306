
// ============================================
// FOOTER
// ============================================

.c-footer {
    $c: &;
    
    &__column{
        @apply pl-6 pt-6 w-full;
        @include breakpoint (sm) {
            @apply w-auto pl-12;
        }
        @include breakpoint (lg) {
            @apply pl-16;
        }
        
        &--newsletter-subscription{
            @include breakpoint (xs) {
                @apply w-full;
            }
        }
    }
}
