
// ============================================
// LOGO
// ============================================

.c-logo {
    $c: &;
    
    &__image{
        min-width: 191px;
        min-height: 110px;
    }
}
