
// ============================================
// LINK
// ============================================

.c-link {
    $c: &;
    
    @apply inline-flex items-center text-0 leading-none; 

    &__label {
        @apply relative font-primary uppercase tracking-wider transition whitespace-no-wrap text-13 text-default font-bold leading-none;
        
        &:before {
            @apply absolute -mb-0.5 pin-l pin-b w-full h-px bg-primary;
            content: '';
            transition: background .6s ease, -webkit-transform .7s cubic-bezier(.19,1,.22,1);
            transition: transform .7s cubic-bezier(.19,1,.22,1), background .6s ease;
            transform: scaleX(0);
            transform-origin: right;
        }
        
    }
    
    &__icon{
        @apply inline-flex justify-center items-center leading-0 transition;
    }
    
    &__svg{
        @apply fill-current transition;
    }
    
    &:hover, &:active{
        #{$c}{
            // X
            &__label{
                &:before{
                    transform: scaleX(1);
                    transform-origin: left;
                }
            }
        }
        
    }
    
    &--primary {
        #{$c}{
            // X
            &__svg{
                @apply text-primary;
            }
            &__label{
                @apply text-default;
                &:before{
                    @apply bg-primary;
                }
            }
        }
      
        &:active,
        &:hover {
            #{$c} {
                // X
                &__label {
                    @apply text-primary;
                }
            }
        }  
    }
    
    &--underlined{
        
        #{$c}{
            // X
            &__label{
                &:before{
                    transform: scaleX(1);
                    transform-origin: left;   
                }
            }
        }
        
        &:hover, &:active{
            #{$c}{
                // X
                &__label{
                    &:before{
                        transform: scaleX(0);
                        transform-origin: right;
                    }
                    
                }
            }
           
        }
        
    }
    
    &--secondary {
        #{$c}{
            // X
            &__label{
                @apply text-secondary;  
            }
        }
        &:active,
        &:hover {
            #{$c} {
                // X
                &__label {
                    @apply text-primary;
                }
            }
        } 
    }
    
    &--read-more{
        #{$c}{
            // X
            &__label{
                margin-top: 2px;
                @apply ml-2.5;
            }
        }
        &:hover, &:active{
            #{$c}{
                // X
                &__svg{
                    transform: rotate(180deg);
                }
            }
        }
    }
    
    &--prev{
        #{$c}{
            // X
            &__label{
                @apply text-default ml-2;
            }
            &__svg{
                width: 21.42px;
                height: 15px;
                @apply text-primary -mt-px;
            }
        }
    }
    
    &--next{
        #{$c}{
            // X
            &__label{
                @apply text-default mr-2;
            }
            &__svg{
                width: 21.42px;
                height: 15px;
                @apply text-primary -mt-px;
            }
        }
    }

}
