
// ============================================
// FORM ELEMENTS
// ============================================

label {
    @apply cursor-pointer;
}

input,
textarea,
select,
button {
    @apply w-full resize-none appearance-none bg-transparent border-0 transition;
    &:focus {
        @apply outline-none
    }
}

button{
    @apply w-auto;
}

select {
    @apply cursor-pointer;
}

input[type='radio'],
input[type='checkbox'] {
    @apply w-auto p-0 border-0 rounded-none;
}

input[type='radio'] {
    -webkit-appearance: radio;
}

input[type='checkbox'] {
    -webkit-appearance: checkbox;
}
