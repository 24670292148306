
// ============================================
// GOOGLE MAPS
// ============================================

.c-google-maps {
    $c: &;
    &--contact{
        @apply w-full h-32;
        @include breakpoint (sm) {
            @apply absolute pin-l pin-y h-full;
            width: calc(50vw - #{$baseline} * 2.5);    
        }
    }
}
