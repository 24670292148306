
// ============================================
// B / STRONG
// ============================================

b, strong{
    @apply font-primary font-semibold;
    em, i{
        @apply font-primary font-semibold italic;
    }
}