
// ============================================
// SLICK VENDOR SETTINGS
// ============================================

$slick-font-family: "icons";
$slick-loader-path: '../images/vendor/slick/';
$slick-arrow-color: white;
$slick-dot-color: black;
$slick-dot-color-active: $slick-dot-color;
$slick-prev-character: "\f102";
$slick-next-character: "\f103";
$slick-dot-character: "\f106";
$slick-dot-size: 6px;
$slick-opacit: 0.75;
$slick-opacity-on-hover: 1;
$slick-opacity-not-active: 0.25;
