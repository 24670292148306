
// ============================================
// SCROLL DOWN
// ============================================

.c-scroll-down {
    &__inner {
        height: 100%;
    }

    &__stripes {
        position: relative;
        bottom: 0;

        width: 1px;
        height: $baseline * 8;
        margin: 0 auto;
    }

    &__stripe {
        @apply bg-white w-px pin-t pin-l absolute;
        &--bg {
            @apply bg-transparent pin-b;
        }
    }

    &__button {
        display: block;

        height: 100%;

        cursor: pointer;
        &:hover,
        &:active {
        }
    }
    
}
