// ============================================
// YEAR DOT
// ============================================

.c-year-dot {

    $c: &;
    margin-top: - $baseline * 12;
    @include breakpoint (md) {
        margin-top: - $baseline * 17;    
    }
    &:before{
        content: "";
        @apply w-14 h-14 border border-primary absolute rounded-full pin-l pin-t;
        margin-left: 7px;
        margin-top: 7px;
        @include breakpoint (md) {
            @apply w-22 h-22;
        }
    }
    &__content{
        
    }
    
    &__year{
        
    }
    
}