
// ============================================
// DESCRIPTION
// ============================================

.c-description {
    $c: &;
    
    @apply font-primary;

    h2 {
    }
    h3 {
    }
    h4 {
    }
    h2,
    h3,
    h4 {
        @apply mt-4 text-black leading-loose;
    }
     > h2,
     > h3,
     > h4 {
        @apply mt-4;
        &:first-child {
            @apply mt-0;
        }
    }

    strong,
    b {
        @apply font-primary;
    }

    p {
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        @apply mt-2;

        &:first-child {
            @apply mt-0;
        }
    }

    a {
        @apply font-primary text-primary transition;

        &:active,
        &:hover {
            @apply text-primary;
        }
    }

    ol {
        @apply mt-3;

        li {
            @apply mt-1;

            &:last-child {
                @apply mt-0;
            }
        }
    }

    ul {
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        @apply mt-3 text-left;

        li {
            font-family: inherit;
            font-size: inherit;
            line-height: inherit;
            @apply relative pb-0.5 pl-2;

            &:before {
                content: '';

                color: inherit;
                background-size: $baseline / 2 auto;

                @include background-svg($list-style-dot, inherit);
                @apply absolute w-1 h-1 pin-l mt-1 bg-no-repeat bg-left-top bg-top fill-current;
            }

            ul {
                @apply mt-1;
                li {
                    @apply pb-2;
                    &:last-child {
                        @apply pb-0;
                    }
                }
            }

            &:last-child {
                @apply pb-0;
            }
        }

        &:first-child {
            @apply mt-0;
        }
    }

    blockquote {
        @apply mt-3 ml-0 pl-3 text-grey-light border-l border-grey-light italic;
        
        &:first-child {
            @apply mt-0;
        }
    }

    img {
        width: auto;
        max-width: 100% !important;
        height: auto !important;
        max-height: 100% !important;
    }
    
}
