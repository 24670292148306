
// ============================================
// CONTACT DETAILS
// ============================================

.c-contact-details {
    $c: &;
    
    &__item{
        &--vat-number{
            @apply hidden;
        }
    }
    
    &--contact{
        #{$c}{
            // X
            &__item{
                @apply mb-3;
                &:last-child{
                    @apply mb-0;
                }
                &--company-name{
                    @apply mb-0;
                    #{$c}{
                        // X
                        &__label{
                            @apply font-secondary-bold text-28;       
                        }
                    }
                }
            }
            
            &__label{
                @apply font-light text-20 text-default;
            }
            
            &__icon{
                margin-top: $baseline / 4;
                width: $baseline * 7;
            }
            
            &__svg{
                @apply text-primary;
                &--pin{
                    width: 24px;
                    height: 29.2px;
                }
                &--email{
                    width: 27px;
                    height: 20px;
                }
                &--phone{
                    width: 25px;
                    height: 28.84px;
                }
            }
            &__link{
                &:hover, &:active{
                    #{$c}{
                        // X
                        &__label{
                            @apply text-primary;
                        }
                    }
                }
            }
        }
    }
    
    &--header{
        #{$c}{
            // X
            &__label{
                @apply text-default tracking-wide text-14 whitespace-no-wrap;
            }
            &__link{
                &:hover, &:active{
                    #{$c}{
                        // X
                        &__label{
                            @apply text-primary;
                        }
                    }
                }
            }
        }
    }
    
    &--footer{
        
        #{$c}{
            // X
            &__label{
                @apply text-default font-light text-14;
            }
            &__item{
                &--company-name{
                        
                    #{$c}{
                        // X
                        &__label{
                            @apply tracking-wider font-semibold uppercase leading-loose whitespace-no-wrap;
                        }
                    }
                }
            }
            
            &__link{
                &:hover, &:active{
                    #{$c}{
                        // X
                        &__label{
                            @apply text-primary;       
                        }
                    }
                }
            }
        }
    }
}
