
// ============================================
// GALLERY
// ============================================

.c-gallery {
    $c: &;
    
    &__bg{
        @apply absolute z-1 pin-x pin-y w-full h-full transition bg-no-repeat bg-center bg-cover;
    }
    
    &__arrow {
        @apply absolute pin-y;
        width: 120px;
        
        &--prev {
            @apply pin-l;
        }

        &--next {
            @apply pin-r;
        }

        &:hover,
        &:active {
            
            #{$c} {
                // X
                &__arrow {
                    
                }
            }
        }
        
    }
    
    &--default {
        .slick-track, .slick-list {
            
            @include breakpoint (sm) {
                height: 100% !important;
            }
            
        }
        
        #{$c} {
            // X
            &__item{
                @apply h-auto;
                @include breakpoint (sm) {
                    @apply h-full;
                }
            }
            
            &__bg-wrapper {
                @apply hidden;
                @include breakpoint (sm) {
                    @apply block;
                }
            }

            &__figure {
                @apply block;
                @include breakpoint (sm) {
                    @apply hidden;
                }
            }
        }
    }
    
    &--only-figure {
        #{$c} {
            // X
            &__figure {
                @apply block;
            }
            &__bg-wrapper{
                @apply hidden;
            }
        }
    }
    
    &--only-bg {
        #{$c} {
            // X
            &__figure {
                @apply hidden;
            }
            &__bg-wrapper{
                @apply block;
            }
        }
    }

    &--no-bg {
        @apply h-auto;

        #{$c} {
            // X
            &__item {
                @apply align-top h-auto;
            }
            &__list {
                @apply h-auto;
            }
            &__figure {
                @apply block;
            }
            &__bg-loader,
            &__bg {
                @apply hidden;
            }
        }
    }

    &--absolute {
        @apply absolute pin-y pin-x overflow-hidden;
        .slick-list {
            height: 100% !important;
        }
    }
    
    &--horse-detail{
        #{$c}{
            // X
            &__item{
                margin: 0px $baseline / 4;
                
                &.slick-slide{
                    #{$c}{
                        // X
                        &__overlay{
                            @apply opacity-100 bg-black-60;
                        }
                    }
                }
                &.slick-center{
                    #{$c}{
                        // X
                        &__overlay{
                            @apply opacity-0;
                        }
                        &__link{
                            cursor: zoom-in;
                        }
                    }
                }
            }
            &__arrows{
                @apply hidden;
                @include breakpoint (md) {
                    @apply block;
                }
            }
        }
        &#{$c}--only-1-slide{
            #{$c}{
                // X
                &__link{
                    cursor: zoom-in;
                }
            }
        }
    }
    
    
}
