
// ============================================
// DIVIDER
// ============================================

.c-divider {
    $c: &;

    @apply bg-white-30 h-px w-full;
    &--light {
        @apply bg-white-20;
    }
}
