// ============================================
// FOLDABLE TEXT
// ============================================

.c-foldable-text {

    $c: &;
    
    #{$c}{
        // X
        &__icon--plus{
            @apply block text-primary;
        }
        &__icon--minus{
            @apply hidden text-primary;
        }
    }
    
    &.is-opened{
        #{$c}{
            // X
            &__icon--plus{
                @apply hidden;
            }
            &__icon--minus{
                @apply block;
            }
        }    
    }
    
}