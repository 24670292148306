
// COLORS
// ============================================

$default          : config('colors.default');

$primary          : config('colors.primary');
$secondary        : config('colors.secondary');
$tertiary         : config('colors.secondary');

$grey             : config('colors.grey');
$red              : config('colors.red');

$black            : config('colors.black');
$grey-darkest     : config('colors.grey-darkest' );
$grey-darker      : config('colors.grey-darker' );
$grey-dark        : config('colors.grey-dark' );
$grey             : config('colors.grey' );
$grey-light       : config('colors.grey-light' );
$grey-lighter     : config('colors.grey-lighter' );
$grey-lightest    : config('colors.grey-lightest');
$white            : config('colors.white');
$bordeaux            : config('colors.bordeaux');


$facebook-color   : #4362a8;
$instagram-color  : #396d9a;
$linkedin-color   : #006db3;
$twitter-color    : #00a9f1;
$vimeo-color      : #48b6ed;
$youtube-color    : #f33;
$pinterest-color  : #ce1a19;
