
// ============================================
// ANCHOR LINK
// ============================================

a {
    @apply no-underline text-primary;
    &:hover, &:active {
        @apply no-underline text-primary;
    }
}
