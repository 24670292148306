
// ============================================
// SELECT
// ============================================

.c-select {
    @apply relative;
    &__select {
        font-size: inherit;
        font-family: inherit;
        @apply h-full px-3 py-2 border border-grey-lighter rounded-none bg-white;
        &.has-error {
            @apply border border-red;
        }
    }

    &__icon {
        @apply absolute pin-y pin-r mr-3 pointer-events-none text-primary;
    }

    &__svg {
        width: 12px;
        height: 7px;
        @apply fill-current;
    }
}
