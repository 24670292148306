
// MATTIAS DOMINGUEZ LOGO
// ============================================

.c-mattiasdominguez-logo {
    $c: &;

    &__label {
        &-inner {
            @include breakpoint (sm) {
                transform: translateX(100%);
            }
        }
    }
  
    &__link {
        &:hover {
            #{$c} {
                // X
                &__label {
                    &-inner {
                        @include breakpoint (sm) {
                            transform: translateX(0);    
                        }
                    }
                }
               
            }
        }
    }
}
