// ============================================
// BUTTON
// ============================================

.c-button {
	$c: &;

	@apply relative px-3 py-2 overflow-hidden items-center justify-center inline-flex transition;

	&__label {
		color: inherit;
		font-family: inherit;
		@apply flex items-center relative z-10 transition font-semibold uppercase tracking-wider;
	}
	
	&__icon{
		@apply flex items-center justify-center;
	}
	
	&__svg{
		&--download{
			width: 14px;
			height: 22px;
			@apply text-default;
		}	
	}
	
	&--primary {
		@apply bg-primary border border-primary;
		#{$c} {
			// X
			&__icon{
				@apply text-white;
			}
			&__label {
				@apply text-white;
			}
		}
		&:hover, &:active, &:focus{
			@apply bg-primary-70;
			#{$c}{
				// X
				&__icon{
					// @apply text-primary;
				}
				&__label{
					// @apply text-primary;
				}
			}
		}
		&.is-disabled{
			@apply bg-primary;
			&:hover, &:active, &:focus{
				@apply bg-primary;
			}
		}
	}
	
    &--white{
		@apply border border-white bg-white;
        #{$c}{
			// X
            &__label{
                @apply text-white;
            }
		}
		&:hover, &:active, &:focus{
			@apply border-primary bg-primary;
			#{$c}{
				// X
				&__label{
					@apply text-white;
				}
			}
		}
    }
	
	&--ghost{
		@apply bg-transparent border;
		#{$c}{
			// X
			&__label{
				
			}
		}
	}
	
	&--primary-ghost{
		@apply bg-transparent border border-primary;
		
		#{$c}{
			// X
			&__label{
				@apply text-default;
			}
		}
		
		&:hover, &:active, &:focus, &.is-active{
			@apply bg-primary;
			#{$c}{
				// X
				&__label{
					@apply text-white;
				}
				&__svg{
					@apply text-white;
				}
			}
		}
	}
	
	&--disabled{
		@apply opacity-25 pointer-events-none;
	}
	
	&--next{
		#{$c}{
			// X
			&__icon{
				@apply pl-1;
			}
		}
	}
	
}
