
// ============================================
// HEADINGS
// ============================================

h1,
h2,
h3,
h4 {
    @apply m-0 font-secondary-medium leading-tight roman font-regular;
}
