
// ============================================
// FILTERS BOX
// ============================================

.c-filters-box {
    $c: &;
    
    &__button {
        
        &-label{
            
        }
        
        &-svg {
            width: 14px;
            height: 8px;
        }
        
        &:hover, &:active{
            // @apply bg-gray-800;
            #{$c}{
                // X
                &__button{
                    // X
                    &-label{
                        // @apply text-white;
                    }
                    &-svg{
                        // @apply text-white;
                    }
                }
            }
        }
        
        &.is-opened {
            @apply rounded-b-none bg-primary;
            #{$c} {
                // X
                &__button {
                    // X
                    &-label{
                        // @apply text-white;
                    }
                    &-svg {
                        @apply text-white;
                        transform: rotate(-180deg);
                    }
                }
            }
        }
        
    }
    
}
