// ============================================
// ALERT
// ============================================

.c-alert {

    $c: &;
    @apply px-3 py-2;
    &--error{
        @apply bg-red-20 text-red;
    }
    
}