
// ============================================
// RADIO
// ============================================

.c-radio {
    $c: &;
    
    &__label {
        @apply relative block pl-6 h-3;

        &:before,
        &:after {
            @apply absolute pin-t pin-l inline-block w-3 h-3 transition;
            content: '';
        }
        
        &:before {
            @apply border rounded-full bg-white;
        }
        
        &:after {
            content: '';
            @apply opacity-0 bg-repeat bg-center;

            @include background-svg($dot, $primary);
        }
    }

    &__input {
        @apply hidden;
        &:checked {
            & + .c-radio__label {
                &:after {
                    @apply opacity-100;
                }
            }
        }
    }
}
