
// ============================================
// FORM
// ============================================

.c-form {
    $c: &;
    
    &__row { 
        @apply -ml-2 text-0;
    }

    &__column {
        
        @apply w-full pt-2 pl-2;
        
        &--50 {
            @include breakpoint (sm) {
                @apply w-1/2;
            }
        }
        &--33 {
            @include breakpoint (sm) {
                @apply w-1/2;
            }
            @include breakpoint (md) {
                @apply w-1/3;
            }
        }
        &--25 {
            @include breakpoint (sm) {
                @apply w-1/2;
            }
            @include breakpoint (md) {
                @apply w-1/4;
            }
        }
        &--20 {
            @include breakpoint (sm) {
                @apply w-1/2;
            }
            @include breakpoint (md) {
                @apply w-1/5;
            }
        }

        &--mailing-list {
            @apply hidden;
        }
        
    }
    
    &__field{
        @apply relative;
        
        &.has-focus{
            #{$c}{
                // X
                &__label{
                    @apply opacity-100;
                }
                &__input{
                    @include placeholder('','', '', '', '', 0);
                }
            }
        }
        &--submit{
            @apply pt-2;
            @include breakpoint (sm) {
                @apply pt-4;    
            }
        }   
    }
    
    &__input{
        @apply text-16; 
        @include breakpoint (sm) {
            @apply pr-20;
        }
        
    }
    
    
    &__label{
        @apply block pb-0.5 opacity-0 text-12 text-primary uppercase;
        &--submit,
        &--general-terms-and-conditions,
        &--checkbox{
            @apply hidden;
        }

    }
    
    &__button{
        @apply px-6;
        &-label{
            @apply text-16;
        }
    }
   
}
