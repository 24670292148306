
// ============================================
// ORDER
// ============================================

@for $i from 1 through 5 {
    
    @responsive {
        .order-#{$i} {
            order: $i;
        }
    }
    
}