// ============================================
// PAGE HEADER
// ============================================

.c-page-header {

    $c: &;

    &__sub-title{
        @apply font-primary font-semibold;        
    }
    
}