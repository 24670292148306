// ============================================
// HORSE DETAILS
// ============================================

.c-horse-details {

    $c: &;
    
    &__item{
        @apply flex flex-col my-1.5 -ml-4;
        @include breakpoint (sm) {
            @apply flex-row; 
        }
    }
    
    &__label, &__value{
        @apply w-1/2 text-18 pl-4;
    }
    
    &__value{
        @apply font-light;
    }
    
}